<template>
  <div class="w-full">
    <app-table
      :apiResponse="list_business"
      :showEmptyButton="false"
      :loading="apiPaginationLoading"
      @params-changed="params_changed"
      :filters="[
        {
          key: 'filter[id]',
          type: 'select',
          placeholder: $t('business.table.header_businss_name'),
          items: all_business,
        },
        {
          key: 'filter[submitted_at_between]',
          type: 'date',
          placeholder: 'Created At',
          value: '',
        },
        {
          key: 'filter[type_of_business_id]',
          type: 'select',
          placeholder: 'Business Type',
          items: typeOfBusinesses,
        },
        {
          key: 'filter[detail.industry_id]',
          type: 'select',
          placeholder: 'Industry',
          items: industries,
        },
        {
          key: 'filter[country_id]',
          type: 'select',
          placeholder: 'Country',
          items: countries,
        },
      ]"
      @on-item-click="onItemClick"
    >
      <template v-slot:header>
        <td>
          <b>{{ $t("business.table.header_businss_name") }}</b>
        </td>
        <td>
          <b>{{ $t("business.table.header_businss_type") }}</b>
        </td>
        <td>
          <b>{{ $t("business.table.header_industry") }}</b>
        </td>
        <td>
          <b>{{ $t("business.table.header_country") }}</b>
        </td>
        <td>
          <b>{{ $t("business.table.header_submitted_date") }}</b>
        </td>
        <td>
          <b>{{ $t("business.table.header_status") }}</b>
        </td>
      </template>
      <template v-slot:body="data">
        <td>
          <p>
            {{ data.model?.name }}
          </p>
        </td>
        <td>
          <p>{{ data.model?.type_of_business?.description ?? "-" }}</p>
        </td>
        <td>
          <p>{{ data.model?.detail?.industry?.name ?? "-" }}</p>
        </td>
        <td>
          <p>{{ data.model?.country?.name ?? "-" }}</p>
        </td>
        <td>
          <p>
            {{
              data.model?.submitted_at
                ? $moment(data.model.submitted_at).format("DD MMMM YYYY")
                : "-"
            }}
          </p>
        </td>
        <td>
          <app-badge :status="getStatus(data.model?.business_status_id)">
            {{ data.model?.status?.name ?? "-" }}
          </app-badge>
        </td>
      </template>
    </app-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryParams: "",
    };
  },
  created() {
    this.fetchListBusiness();
  },
  computed: {
    business() {
      return this.$store.getters["businessStore/business"];
    },
    list_business() {
      return this.$store.getters["masterBusinessStore/list_business"];
    },
    apiPaginationLoading() {
      return this.$store.getters["masterBusinessStore/apiPaginationLoading"];
    },
    all_business() {
      return this.$store.getters["masterBusinessStore/all_business_for_select"];
    },
    typeOfBusinesses() {
      return this.$store.getters["referenceStore/typeOfBusinesses"];
    },
    industries() {
      return this.$store.getters["referenceStore/industries"];
    },
    countries() {
      return this.$store.getters["referenceStore/countries"];
    },
  },
  mounted() {
    this.fetchListBusiness();
    this.$store.dispatch("masterBusinessStore/getMasterBusinessForOperation", {
      master_business_id: this.business.id,
    });
    this.$store.dispatch("referenceStore/getTypeOfBusinesses", 0);
    this.$store.dispatch("referenceStore/getIndustries", 0);
    this.$store.dispatch("referenceStore/getCountries", 0);
  },
  methods: {
    fetchListBusiness() {
      this.$store.dispatch(
        "masterBusinessStore/getListMasterBusinessForOperation",
        {
          master_business_id: this.business.id,
          queryParams: this.queryParams,
        }
      );
    },
    getStatus(status_id) {
      switch (status_id) {
        case 1:
          return "light";
        case 2:
          return "light";
        case 3:
          return "success";
        case 4:
          return "error";
        case 5:
          return "warning";
        default:
          return "light";
      }
    },
    params_changed(filters, queryParams) {
      this.queryParams = queryParams;
      this.fetchListBusiness();
    },
    onItemClick(data){
      window.location.href = this.$router.resolve({ name: "business-detail", params: { business_id: data.id }}).href;
    }
  },
};
</script>
